import React from 'react';
import LiveQRContainer from '@modules/LiveQR/LiveQR.container';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

interface ServerSideProps {
  locale: string
}

const index = () => <LiveQRContainer />;

export async function getServerSideProps(props: ServerSideProps) {
  const { locale } = props;

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'validations'])),
    },
  };
}

export default index;
